import { Chip } from '@mui/material';
import moment from 'moment';

export const SUBDOMAINS = {
  DEFAULT: 'portal',
  SUNBELT: 'sunbelt',
  UNITED_RENTALS: 'ur',
  AGGREKO: 'aggreko',
  REAL_MANAGE: 'realmanage',
};

export const CURRENT_SUBDOMAIN = (() => {
  const current = window.location.host.split('.')[0];
  for (const value of Object.values(SUBDOMAINS)) if (current === value) return value;
  return SUBDOMAINS.DEFAULT;
})();

export const SUBDOMAIN_TEMPLATE = {
  LOGIN_BACKGROUND: {
    [SUBDOMAINS.DEFAULT]: {
      backgroundImage: `url(${require('assets/login/truck_default.png')}), url(${require('assets/login/login-bg.png')})`,
      backgroundPosition: { xs: '20% 100%, center center', sm: '20% 90%, center center' },
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: { xs: '100%, cover', sm: 'auto, cover' },
    },
    [SUBDOMAINS.SUNBELT]: {
      backgroundImage: `url(${require('assets/login/truck_sunbelt.png')}), url(${require('assets/login/login-bg.png')})`,
      backgroundPosition: { xs: '20% 100%, center center', sm: '30% 90%, center center' },
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: { xs: '95%, cover', sm: '800px, cover' },
    },
    [SUBDOMAINS.UNITED_RENTALS]: {
      backgroundImage: `url(${require('assets/login/truck_united.png')}), url(${require('assets/login/login-bg.png')})`,
      backgroundPosition: { xs: '20% 100%, center center', sm: '30% 90%, center center' },
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: { xs: '100%, cover', sm: '800px, cover' },
    },
    [SUBDOMAINS.AGGREKO]: {
      backgroundImage: `url(${require('assets/login/truck_aggreko.png')}), url(${require('assets/login/login-bg.png')})`,
      backgroundPosition: { xs: '20% 100%, center center', sm: '30% 90%, center center' },
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: { xs: '100%, cover', sm: '800px, cover' },
    },
    [SUBDOMAINS.REAL_MANAGE]: {
      backgroundImage: `url(${require('assets/login/building.png')}), url(${require('assets/login/login-bg.png')})`,
      backgroundPosition: { xs: '20% 100%, center center', sm: '22% 80%, center center' },
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: {
        xs: '100%, cover',
        sm: '400px, cover',
        md: '400px, cover',
        lg: '450px, cover',
        xl: '650px, cover',
      },
    },
  }[CURRENT_SUBDOMAIN],

  LOGIN_LOGOS: {
    [SUBDOMAINS.DEFAULT]: (
      <div>
        <img src={require('assets/logo_blue_nasdaq.png')} alt="" style={{ width: '170px' }} />
      </div>
    ),
    [SUBDOMAINS.SUNBELT]: (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <img
            src={require('assets/login/logo_sunbelt.png')}
            alt=""
            width={80}
            height={65}
            style={{ borderRadius: 10 }}
          />
        </div>
        <div>
          <img src={require('assets/logo_blue_nasdaq.png')} alt="" width={180} />
        </div>
      </div>
    ),
    [SUBDOMAINS.UNITED_RENTALS]: (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <img src={require('assets/login/logo_united.png')} width={160} alt="" />
        </div>
        <div>
          <img src={require('assets/logo_blue_nasdaq.png')} width={160} alt="" />
        </div>
      </div>
    ),
    [SUBDOMAINS.AGGREKO]: (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <img src={require('assets/login/logo_aggreko.png')} width={200} alt="" />
        </div>
        <div>
          <img src={require('assets/logo_blue_nasdaq.png')} width={160} alt="" />
        </div>
      </div>
    ),
    [SUBDOMAINS.REAL_MANAGE]: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <img src={require('assets/login/logo_realmanage.webp')} alt="" width={160} />
        </div>
        <div>
          <img src={require('assets/logo_blue_nasdaq.png')} alt="" width={160} />
        </div>
      </div>
    ),
  }[CURRENT_SUBDOMAIN],

  LOGIN_FORM_TEXT: {
    [SUBDOMAINS.DEFAULT]:
      'Gas delivered to your fleet vehicles During downtime to maximize your fleet’s efficiency',
    [SUBDOMAINS.SUNBELT]: 'Manage your fuel orders right from our platform',
    [SUBDOMAINS.UNITED_RENTALS]: 'Manage your fuel orders right from our platform',
    [SUBDOMAINS.AGGREKO]: 'Manage your fuel orders right from our platform',
    [SUBDOMAINS.REAL_MANAGE]: 'Manage your fuel orders right from our platform',
  }[CURRENT_SUBDOMAIN],

  LOGIN_TITLE: {
    [SUBDOMAINS.DEFAULT]: 'ULEASH YOUR FLEET',
    [SUBDOMAINS.SUNBELT]: 'SUNBELT EQUIPMENT FUEL ORDERING SYSTEM',
    [SUBDOMAINS.UNITED_RENTALS]: 'UNITED RENTALS EQUIPMENT FUEL ORDERING SYSTEM',
    [SUBDOMAINS.AGGREKO]: 'AGGREKO EQUIPMENT FUEL ORDERING SYSTEM',
    [SUBDOMAINS.REAL_MANAGE]: 'REAL MANAGE PROPERTIES',
  }[CURRENT_SUBDOMAIN],

  LOGIN_LIST_ALIGN: {
    [SUBDOMAINS.DEFAULT]: 'flex-start',
    [SUBDOMAINS.SUNBELT]: 'flex-start',
    [SUBDOMAINS.UNITED_RENTALS]: 'flex-end',
    [SUBDOMAINS.AGGREKO]: 'flex-end',
    [SUBDOMAINS.REAL_MANAGE]: 'flex-end',
  }[CURRENT_SUBDOMAIN],
  LOGIN_LIST_ITEMS: {
    [SUBDOMAINS.DEFAULT]: [
      'Easy Fleet Management',
      'Fleet Dashboard with Reports',
      'Fuel Usage Reports',
      'Driver Utilization Reports',
      'Recurring Orders',
    ],
    [SUBDOMAINS.SUNBELT]: [
      'Easy Fuel Ordering System',
      'Equipment Fuel Usage Report',
      "Manage Your Job Site's Equipment",
      'Reorder and Recurring Orders',
    ],
    [SUBDOMAINS.UNITED_RENTALS]: [
      'Easy Fuel Ordering System',
      'Equipment Fuel Usage Report',
      "Manage Your Job Site's Equipment",
      'Reorder and Recurring Orders',
    ],
    [SUBDOMAINS.AGGREKO]: [
      'Easy Fuel Ordering System',
      'Equipment Fuel Usage Report',
      "Manage Your Job Site's Equipment",
      'Reorder and Recurring Orders',
    ],
    [SUBDOMAINS.REAL_MANAGE]: [
      'Easy Fuel Ordering System',
      'Manage Your Fuel Consumption',
      'Fast Dispatch Support',
      'Emergency Fuel Assistant',
      'Customized Fuel Plans',
    ],
  }[CURRENT_SUBDOMAIN],
};

export const FUEL_VALUE = {
  REGULAR: '87',
  PREMIUM: '93',
  REC90: '90',
  DIESEL: 'ulsd',
  RED_DYE_DIESEL: 'usldred',
};

export const FUEL_LABEL = {
  [FUEL_VALUE.REGULAR]: 'Regular',
  [FUEL_VALUE.PREMIUM]: 'Premium',
  [FUEL_VALUE.REC90]: 'REC90',
  [FUEL_VALUE.DIESEL]: 'Diesel',
  [FUEL_VALUE.RED_DYE_DIESEL]: 'Dyed (Red) Diesel',
};

export const FUEL_OPTIONS = [
  { label: FUEL_LABEL[FUEL_VALUE.REGULAR], value: FUEL_VALUE.REGULAR },
  { label: FUEL_LABEL[FUEL_VALUE.PREMIUM], value: FUEL_VALUE.PREMIUM },
  { label: FUEL_LABEL[FUEL_VALUE.REC90], value: FUEL_VALUE.REC90 },
  { label: FUEL_LABEL[FUEL_VALUE.DIESEL], value: FUEL_VALUE.DIESEL },
  { label: FUEL_LABEL[FUEL_VALUE.RED_DYE_DIESEL], value: FUEL_VALUE.RED_DYE_DIESEL },
];

export const ASSET_TYPE = {
  VEHICLE: 'vehicle',
  TANK: 'tank',
  GENERATOR: 'generator',
  EQUIPMENT: 'equipment',
};

export const ASSET_TYPE_LABEL = {
  [ASSET_TYPE.VEHICLE]: 'Vehicle',
  [ASSET_TYPE.TANK]: 'Tank',
  [ASSET_TYPE.GENERATOR]: 'Generator',
  [ASSET_TYPE.EQUIPMENT]: 'Equipment',
};

export const ASSET_TYPES = [
  { value: ASSET_TYPE.VEHICLE, label: ASSET_TYPE_LABEL[ASSET_TYPE.VEHICLE] },
  { value: ASSET_TYPE.TANK, label: ASSET_TYPE_LABEL[ASSET_TYPE.TANK] },
  { value: ASSET_TYPE.GENERATOR, label: ASSET_TYPE_LABEL[ASSET_TYPE.GENERATOR] },
  { value: ASSET_TYPE.EQUIPMENT, label: ASSET_TYPE_LABEL[ASSET_TYPE.EQUIPMENT] },
];

export const ASSET_FUEL_LVL = {
  UNKNOWN: 'unknown',
  EMPTY: 'empty',
  QUARTER: '25%',
  HALF: '50%',
  THREE_QUARTERS: '75%',
};

export const ASSET_FUEL_LVL_LABELS = {
  [ASSET_FUEL_LVL.UNKNOWN]: 'Unknown',
  [ASSET_FUEL_LVL.EMPTY]: 'Empty',
  [ASSET_FUEL_LVL.QUARTER]: '25%',
  [ASSET_FUEL_LVL.HALF]: '50%',
  [ASSET_FUEL_LVL.THREE_QUARTERS]: '75%',
};

export const windowsOptions = [
  { value: 'default', label: 'Open' },
  { value: 'morning', label: 'Morning ( ≈ 9am to 1pm)' },
  { value: 'afternoon', label: 'Afternoon ( ≈ 1pm to 5pm)' },
  { value: 'night', label: 'Night ( ≈ 5pm to 10pm)' },
];

export const Ucfirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toFloatAndDecimal = value => {
  if (typeof value === 'number' && value < 1000) return value.toFixed(2);
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name, styles = {}) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...styles,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export const defaultCardHeader = {
  background: '#BBB',
  color: '#000',
  width: '100%',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  padding: '15px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  fontSize: '12px',
};

export const getChipColorByStatus = ({ status, value }) => {
  if (status && status.toLowerCase() === value) {
    return 'success';
  }

  return 'warning';
};

export const getChipByStatus = status => {
  if (!status) return;
  return (
    <Chip
      color={status === INVOICE_STATUS.PAID ? 'success' : 'warning'}
      label={INVOICE_STATUS_LABEL[status]}
      sx={{ minWidth: '100px' }}
    />
  );
};

export const totalGallons = items => {
  var totals = 0;
  for (const item of items) {
    totals += item.quantity;
  }
  return totals;
};

export const recurringServiceStatusColor = {
  active: 'success',
  inactive: 'warning',
};

export const INVOICE_STATUS = {
  PENDING: 'pending',
  PAID: 'paid',
  PARTIALLY_PAID: 'partially_paid',
  VOIDED: 'voided',
};

export const INVOICE_STATUS_LABEL = {
  [INVOICE_STATUS.PENDING]: 'Pending',
  [INVOICE_STATUS.PAID]: 'Paid',
  [INVOICE_STATUS.PARTIALLY_PAID]: 'Partially Paid',
  [INVOICE_STATUS.VOIDED]: 'Voided',
};

export const invoicedStatus = [
  { value: INVOICE_STATUS.PENDING, label: INVOICE_STATUS_LABEL[INVOICE_STATUS.PENDING] },
  { value: INVOICE_STATUS.PAID, label: INVOICE_STATUS_LABEL[INVOICE_STATUS.PAID] },
  {
    value: INVOICE_STATUS.PARTIALLY_PAID,
    label: INVOICE_STATUS_LABEL[INVOICE_STATUS.PARTIALLY_PAID],
  },
  { value: INVOICE_STATUS.VOIDED, label: INVOICE_STATUS_LABEL[INVOICE_STATUS.VOIDED] },
];

export const ORDER_STATUS = {
  PENDING_APPROVAL_DASHBOARD: 'pending_approval_dashboard',
  PENDING: 'pending',
  PARTIALLY_SERVICED: 'partially_serviced',
  IN_ROUTE: 'in_route',
  PAYMENT_FAILED: 'payment_failed',
  CANCEL_CONSUMER: 'cancel_consumer',
  CANCEL_ADMIN: 'cancel_admin',
  SERVICED: 'serviced',
  INVOICED: 'invoiced',
};

export const STORE_ORDER_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  INVOICED: 'invoiced',
  CANCEL_ADMIN: 'cancel_admin',
  CANCEL_CUSTOMER: 'cancel_customer',
};

export const STORE_ORDER_STATUS_LABEL = {
  [STORE_ORDER_STATUS.PENDING]: 'Pending',
  [STORE_ORDER_STATUS.CANCEL_ADMIN]: `Canceled (A)`,
  [STORE_ORDER_STATUS.CANCEL_CUSTOMER]: `Canceled (C)`,
  [STORE_ORDER_STATUS.COMPLETED]: 'Pending Invoice',
  [STORE_ORDER_STATUS.INVOICED]: 'Invoiced',
};

export const STORE_ORDER_STATUS_COLOR = {
  [STORE_ORDER_STATUS.PENDING]: '#4d85db',
  [STORE_ORDER_STATUS.CANCEL_ADMIN]: '#F44336',
  [STORE_ORDER_STATUS.CANCEL_CUSTOMER]: '#F44336',
  [STORE_ORDER_STATUS.COMPLETED]: '#7aaf4c',
  [STORE_ORDER_STATUS.INVOICED]: '#2aad79',
};

export const STORE_ORDER_STATUS_CHIP_COLOR = {
  [STORE_ORDER_STATUS.PENDING]: '#e6f0ff',
  [STORE_ORDER_STATUS.CANCEL_ADMIN]: '#ffe3e3',
  [STORE_ORDER_STATUS.CANCEL_CUSTOMER]: '#ffe3e3',
  [STORE_ORDER_STATUS.COMPLETED]: '#EAFFE7',
  [STORE_ORDER_STATUS.INVOICED]: '#dff7ed',
};

export const STORE_PRODUCT_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
};

export const STORE_PRODUCT_STATUS_LABEL = {
  [STORE_PRODUCT_STATUS.PENDING]: 'Pending',
  [STORE_PRODUCT_STATUS.CANCELED]: `Canceled`,
  [STORE_PRODUCT_STATUS.COMPLETED]: 'Completed',
};

export const STORE_PRODUCT_STATUS_COLOR = {
  [STORE_PRODUCT_STATUS.PENDING]: '#DD8855',
  [STORE_PRODUCT_STATUS.CANCELED]: '#F44336',
  [STORE_PRODUCT_STATUS.COMPLETED]: '#7aaf4c',
};

export const valueOrNA = value => value ?? 'N/A';

export const parseMetrics = data => {
  const { assetsServiced, totalGallons } = data;

  return {
    assetsServiced: {
      lastMonth: valueOrNA(assetsServiced.lastMonth),
      lastQtr: valueOrNA(assetsServiced.lastQtr),
      lastWeek: valueOrNA(assetsServiced.lastWeek),
      thisWeek: valueOrNA(assetsServiced.thisWeek),
    },
    totalGallons: {
      lastMonth: valueOrNA(totalGallons.lastMonth),
      lastQtr: valueOrNA(totalGallons.lastQtr),
      lastWeek: valueOrNA(totalGallons.lastWeek),
      thisWeek: valueOrNA(totalGallons.thisWeek),
    },
  };
};

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function getStartOfWeek(date = new Date()) {
  const momentDate = moment(date);
  const startOfWeek = moment(date).startOf('isoWeek').add(6, 'hours');

  if (momentDate.isBefore(startOfWeek)) {
    startOfWeek.subtract(7, 'days');
  }

  return startOfWeek.toDate();
}

export function getStartOfMonth(date = new Date()) {
  const momentDate = moment(date);
  const startOfMonth = moment(date).startOf('month').add(6, 'hours');

  if (momentDate.isBefore(startOfMonth)) {
    startOfMonth.subtract(1, 'month');
  }

  return startOfMonth.toDate();
}

export const getAddressInfoFromComponents = components => {
  let addressParts = {
    state: '',
    zipcode: '',
    city: '',
  };
  for (const component of components) {
    if (component.types.includes('postal_code')) {
      addressParts = {
        ...addressParts,
        zipcode: component.long_name,
      };
    } else if (component.types.includes('administrative_area_level_1')) {
      addressParts = {
        ...addressParts,
        state: component.short_name,
      };
    } else if (component.types.includes('locality', 'political')) {
      addressParts = {
        ...addressParts,
        city: component.long_name,
      };
    }
  }

  return addressParts;
};

export const formatDate = dateParam => {
  if (!dateParam) return '';
  return moment(dateParam).format('MMM D, YYYY');
};

export function canRequestDef(assetType, fuelType) {
  return (
    [ASSET_TYPE.GENERATOR, ASSET_TYPE.TANK, ASSET_TYPE.EQUIPMENT].includes(assetType) &&
    [FUEL_VALUE.DIESEL, FUEL_VALUE.RED_DYE_DIESEL].includes(fuelType)
  );
}
